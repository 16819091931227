<template>
  <div class="form-group">
    <p class="title-body-content">Campos da consulta</p>
      <multiselect
        id="campos"
        :value="modules"
        :options="modulesOptions"
        track-by="id"
        label="name"
        :close-on-select="false"
        :option-height="40"
        :showLabels="false"
        :internalSearch="true"
        :searchable="true"
        :multiple="true"
        openDirection="bottom"
        class="multiple"
        @select="addModule"
        @remove="removeModule"
        placeholder="Campos da consulta"
      >
        <template slot="caret">
          <div class="chevron">
            <ChevronDown />
          </div>
        </template>
        <template slot="beforeList">
          <div class="select-all-container" @click.prevent="handleAllSelected">
            <Check :value="allSelected" />
            <span>Todos</span>
          </div>
        </template>
        <template slot="selection" slot-scope="{ values }">
          <div class="inline-content">
            <p v-if="modules.length === getAllModules().length">Todos</p>
            <div v-else>
              {{ values.map(el => el.name).join(', ')  }}
            </div>
          </div>
        </template>
        <template slot="noOptions">
          Nenhuma opção
        </template>
        <template slot="noResult">
          Nenhum resultado
        </template>
      </multiselect>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ChevronDown from '@/assets/icons/chevron-down.svg'
import { getAllModules } from '@/utils/modulesHelper.js'
import Check from '@/components/General/Check'

export default {
  components: { ChevronDown, Check },

  computed: {
    ...mapState({
      modules: state => state.attendance.history.modules,
      groups: state => state.attendance.menu.groups,
    }),
  },
  data () {
    return {
      allSelected: true,
      modulesOptions: [],
    }
  },
  created() {
    this.createOptions()
    if(!this.modules)
      this.setModules(getAllModules())
  },
  methods: {
    ...mapActions('attendance/history', ['setModules']),
    getAllModules,
    addModule (data) {
      const payload = this.modules
      payload.push(data)
      if( payload.length === getAllModules().length)
        this.allSelected = true
      this.setModules(payload)
    },
    removeModule (data) {
      this.allSelected = false
      const payload = this.modules.filter(el => { return el.id !== data.id })
      this.setModules(payload)
    },
    handleAllSelected () {
      this.allSelected = !this.allSelected
      if(this.allSelected)
        this.setModules(getAllModules())
      else
        this.setModules([])
    },
    createOptions () {
      const opts = getAllModules()
      const order = opts?.sort((a,b) => {
        if (a.name > b.name) return 1;
        else if (a.name < b.name) return -1
        else return 0
      })

      order.find(el => {
        if(el.id === 'anotacao-cirurgica') {
          el.name = 'Anotações'
        }
      })
      this.modulesOptions = order
    }
  },
  watch: {
    modules: {
      handler() {
        if( this.modules.length === getAllModules().length)
          this.allSelected = true
        else
          this.allSelected = false
      },
      deep: true
    },
    groups: {
      handler(value) {
        this.createOptions()
      },
      deep: true
    }
  },
  mounted () {
    this.setModules(getAllModules())
  }
}
</script>

<style lang="scss" scoped>
.form-group {
  height: 90px;
  margin-bottom: 0 !important;
}

.chevron {
  background-color: var(--neutral-000);
  border-radius: 100%;
}

.inline-content {
  display: flex !important;
  flex-direction: row !important;
  overflow: hidden !important;
  max-width: fit-content !important;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.select-all-container {
  display: flex;
  margin-left: 16px;
}
</style>
